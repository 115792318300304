var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-set" },
    [
      _c(
        "a-popover",
        {
          attrs: {
            trigger: "click",
            placement: "bottomRight",
            align: { offset: [4, 5] },
            "auto-adjust-overflow": false,
            "overlay-class-name": "info-popover",
            "get-popup-container": function (triggerNode) {
              return triggerNode.parentNode
            },
          },
          model: {
            value: _vm.propoVisible,
            callback: function ($$v) {
              _vm.propoVisible = $$v
            },
            expression: "propoVisible",
          },
        },
        [
          _c("template", { slot: "content" }, [
            _c(
              "div",
              { staticClass: "menu-list" },
              [
                _c("div", { staticClass: "person-info" }, [
                  _c(
                    "div",
                    { staticClass: "head-portrait" },
                    [
                      _c("CbAvater", {
                        attrs: {
                          "on-job-id": _vm.accountInfo.onJobId,
                          "avater-name": _vm.accountInfo.staffName
                            ? _vm.accountInfo.staffName
                            : _vm.accountInfo.userName
                            ? _vm.accountInfo.userName
                            : "",
                          size: "36",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "Avatar-tags" },
                    [
                      _vm.accountInfo.userType === "0"
                        ? _c("a-tag", [_vm._v("普通账号")])
                        : _vm._e(),
                      _vm.accountInfo.userType === "1"
                        ? _c("a-tag", [_vm._v("超级管理员")])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.tenants.length > 1
                  ? _c(
                      "div",
                      { staticClass: "function-setting switch-enterprise" },
                      [
                        _c(
                          "a-popover",
                          {
                            attrs: {
                              trigger: "hover",
                              placement: "leftTop",
                              "overlay-class-name": "tenant-popover",
                              align: { offset: [2, 0] },
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "content" },
                              _vm._l(_vm.tenants, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.tenantId,
                                    class: [
                                      item.tenantId === _vm.currentId
                                        ? "content-p active-content"
                                        : "content-p",
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeCompony(item.tenantId)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "a-tooltip",
                                      { attrs: { placement: "left" } },
                                      [
                                        _c("template", { slot: "title" }, [
                                          _c("span", [
                                            _vm._v(_vm._s(item.tenantName)),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v(_vm._s(item.tenantName)),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c(
                              "p",
                              [
                                _vm._v("切换企业"),
                                _c("a-icon", { attrs: { type: "right" } }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.accountInfo.userType === "1" && _vm.isShow === true
                  ? _c("div", { staticClass: "personnel" }, [
                      _c(
                        "div",
                        {
                          staticClass: "personnal",
                          on: { click: _vm.personelModal },
                        },
                        [_c("p", [_vm._v("人数分配")])]
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "function-setting" },
                  [
                    _c("p", { on: { click: _vm.updatePassword } }, [
                      _vm._v("修改密码"),
                    ]),
                    _c("PwdDrawer", { ref: "pwdDrawer" }),
                  ],
                  1
                ),
                _c("DistributionModal", { ref: "distribution" }),
              ],
              1
            ),
            _c("div", { staticClass: "function-setting loginBox" }, [
              _c(
                "p",
                { staticClass: "login-out", on: { click: _vm.loginOut } },
                [_vm._v("安全退出")]
              ),
            ]),
          ]),
          _vm._t("avaterPopover"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }